import {
  Card,
  PaymentProviderInterface,
} from '@/components/classes/PaymentProviders/Deposit/Interfaces/PaymentProviderInterface';
import Utils from '@/modules/casino/utils/Utils';
import { paymentCheckout } from '@/modules/casino/ClientAPI/paths';
import VivaAPI from '@/modules/casino/ClientAPI/VivaAPI';
import PaymentProvider, {
  PaymentProviderConstructor,
} from '@/components/classes/PaymentProviders/Deposit/PaymentProvider';
import {
  DEFAULT_AMOUNT,
  HTML_3DS_IFRAME_ID,
  HTML_3DS_IFRAME_WRAPPER,
  PaymentProvider as PaymentProviderConstant,
  typeCards,
} from '@/constants/paymentProvider';
import {
  requestVivaCardToken,
  requestVivaChargeTokenWithCard,
  requestVivaToken,
  sendVivaChargeToken,
} from '@/modules/casino/store/actions/paymentCheckout';
import ClientAPI from '@/modules/casino/ClientAPI/ClientAPI';
import PlayerAbuseChecker from '@/modules/casino/utils/PlayerAbuseChecker';
import VivaApplePay from '@/components/classes/PaymentProviders/Deposit/Viva/VivaApplePay';

class Viva extends PaymentProvider implements PaymentProviderInterface {
  private applePay: VivaApplePay | undefined;
  private cardsFetched: boolean = false;

  constructor(data: PaymentProviderConstructor) {
    super(data);
    this.setType(PaymentProviderConstant.viva);
    if (data.settings?.cards) {
      this.setCards(data.settings.cards);
    }
  }

  injectjQuery(): void {
    Utils.injectScript('https://code.jquery.com/jquery-1.12.4.min.js', 'viva-jquery', this.jqueryInserted, {
      integrity: 'sha256-ZosEbRLbNQzLpnKIkEdrPv7lOy9C27hHQ+Xp8a4MxAQ=',
      crossorigin: 'anonymous',
    });
  }

  jqueryInserted = (): void => {
    // implement in the future if needed;
  };
  injectScript(): void {
    setTimeout(() => {
      Utils.injectScript(
        window.config.vivaUrl + paymentCheckout.JS_VIVA_PAYMENT,
        'viva-script',
        this.vivaScriptInserted,
        {
          async: true,
        },
      );
    }, 3500);
  }

  destroy(): void {
    super.destroy();
    this.applePay?.destroy();
  }

  init(data: any): void {
    super.init(data);
    // add div for 3ds
    let div = document.getElementById(HTML_3DS_IFRAME_ID);
    if (!div) {
      div = document.createElement('div');
      div.setAttribute('id', HTML_3DS_IFRAME_ID);
      const wrapper = document.getElementById(HTML_3DS_IFRAME_WRAPPER);
      if (wrapper) {
        wrapper.appendChild(div);
      } else {
        document.body.appendChild(div);
      }
    }

    this.injectjQuery();
    this.injectScript();

    this.initVivaApplePay(data);
    this.cardsFetched = data.cardsFetched ?? false;
  }

  initVivaApplePay(data: any): VivaApplePay {
    this.applePay = new VivaApplePay({
      ...data,
      triggerSuccess: this.triggerSuccess,
      triggerError: this.triggerError,
      dispatch: this.dispatch,
      bonusId: data.bonusId ?? null,
      amount: data.amount ?? DEFAULT_AMOUNT,
    });

    return this.applePay;
  }

  setAmount(amount: number): void {
    super.setAmount(amount);
    this.applePay?.setAmount(amount);
  }

  confirmDepositValue() {
    this.auth();
    this.fetchCards();
  }

  auth() {
    // TODO: check if we have the token
    this.dispatch(requestVivaToken()); // auth token
  }
  fetchCards() {
    if (!this.cardsFetched) {
      // this.dispatch(fetchPlayerPaymentMethods()); // fetch cards
      this.dispatch(requestVivaCardToken());
    }
  }
  setCards(cards: any) {
    // check if cards is of type Cards
    let foundDefault = false;
    const cardsList: Card[] =
      cards.length > 0 && cards[0].is_default === undefined
        ? cards
        : cards.map((card: any): Card | undefined => {
          const c = this.createCard(card);

          if (card.is_default) {
            c.selected = true;
            foundDefault = true;
            this.setPaymentDetails({
              ...this.paymentDetails,
              card: c,
            });
          }

          return c;
        });
    if (!foundDefault && cardsList.length > 0 && cards?.[0]?.is_default !== undefined) {
      cardsList[0].selected = true;
      this.setPaymentDetails({
        ...this.paymentDetails,
        card: cardsList[0],
      });
    }
    super.setCards(cardsList);
  }
  setCardsNew(cards: any) {
    // check if cards is of type Cards
    let foundDefault = false;
    const cardsInitialized = cards.length > 0 && cards[0].cardHolderName === undefined;
    const cardsNotInitialized = cards.length > 0 && cards[0].cardHolderName !== undefined;
    const cardsList: Card[] = cardsInitialized
      ? cards
      : cards.map((card: any): Card | undefined => {
        const c = this.createCard(card);

        if (card.is_default) {
          c.selected = true;
          foundDefault = true;
          this.setPaymentDetails({
            ...this.paymentDetails,
            card: c,
          });
        }

        return c;
      });
    if (!foundDefault && cardsNotInitialized) {
      cardsList[0].selected = true;
      this.setPaymentDetails({
        ...this.paymentDetails,
        card: cardsList[0],
      });
    }
    super.setCards(cardsList);
  }
  createCard(card: any): Card {
    const c = super.createCard(card);
    let cardNumber = c.number;
    // format cardNumber to be displayed as **** **** **** 1234
    const cardNumberRaw = cardNumber?.replace(/\s/g, '');
    if (cardNumberRaw?.length === 16) {
      // replace first chars with *
      cardNumber = cardNumberRaw?.replace(/.{12}/, '**** **** **** ') ?? '';
    }
    switch (c.type) {
      case '0':
        c.type = typeCards.visa;
        break;
      case '1':
        c.type = typeCards.mastercard;
        break;
      case '6':
        c.type = typeCards.maestro;
        break;
      default:
      // do nothing
    }

    return { ...c, number: cardNumber };
  }
  createCardNew(card: any): Card {
    const c = super.createCard({
      card_token: card.token,
      card_type_id: card.cardBrand,
      card_number: card.cardNumber,
      expiration_date: card.expirationDate,
      card_full_name: card.cardHolderName,
      bin: card.cardBin,
    });

    let cardNumber = c.number;
    // format cardNumber to be displayed as **** **** **** 1234
    const cardNumberRaw = cardNumber?.replace(/\s/g, '');
    if (cardNumberRaw?.length === 16) {
      // replace first chars with *
      cardNumber = cardNumberRaw?.replace(/.{12}/, '**** **** **** ') ?? '';
    }

    switch (c.type) {
      case '0':
        c.type = typeCards.visa;
        break;
      case '1':
        c.type = typeCards.mastercard;
        break;
      case '6':
        c.type = typeCards.maestro;
        break;
      default:
      // do nothing
    }

    return { ...c, number: cardNumber };
  }

  vivaScriptInserted = () => {
    const token = VivaAPI.getToken();

    if (!token) {
      // @ts-ignore
      setTimeout(this.vivaScriptInserted, 1000);
      return;
    }

    const setupParams = {
      authToken: token,
      baseUrl: window.config.vivaApiUrl,
      cardHolderAuthOptions: {
        cardHolderAuthPlaceholderId: HTML_3DS_IFRAME_ID,
        cardHolderAuthInitiated: function () {
          // @ts-ignore
          document.getElementById(HTML_3DS_IFRAME_ID)?.setAttribute('class', 'show');
        },
        cardHolderAuthFinished: function () {
          // @ts-ignore
          document.getElementById(HTML_3DS_IFRAME_ID)?.setAttribute('class', '');
        },
      },
    };
    // @ts-ignore
    window.VivaPayments.cards.setup(setupParams);
    // @ts-ignore
    window.VivaPayments.setBaseURL(window.config.vivaApiUrl);
  };

  setPaymentDetails(paymentDetails: any, setPaymentDetailsCallBack?: () => void): any {
    this.applePay?.setAmount(paymentDetails?.amount ?? 0);
    this.applePay?.setBonusId(paymentDetails?.bonusId ?? null);

    return super.setPaymentDetails(paymentDetails ?? null, setPaymentDetailsCallBack);
  }

  setBonusId(bonusId: number | null): void {
    super.setBonusId(bonusId);
    this.applePay?.setBonusId(bonusId);
  }

  requestChargeToken(): void {
    if (this.paymentDetails?.card?.token) {
      this.showLoader();
      this.dispatch(
        requestVivaChargeTokenWithCard(this.paymentDetails.card.token, {
          amount: this.paymentDetails.amount,
          bonusId: this.paymentDetails.bonusId,
        }),
      );
    } else {
      this.requestNewChargeToken();
    }
  }

  requestNewChargeToken(): void {
    try {
      const paymentDetails = this.paymentDetails;
      const dispatch = this.dispatch;
      const triggerError = this.triggerError;
      // @ts-ignore
      window.VivaPayments.cards
        .requestToken({
          amount: paymentDetails.amount * 100,
        })
        .done(function (data: any) {
          dispatch(
            sendVivaChargeToken(data, paymentDetails.amount, paymentDetails.bonusId, paymentDetails.holderName ?? ''),
          );
        })
        .fail(function (error: any) {
          triggerError({
            message: 'Failed to get charge token from Viva Payments',
            error: error,
          });
          console.error(error);
        });
    } catch (e) { }
  }
  confirmPayment(): void {
    try {
      this.requestChargeToken();
      // choose one of the following
      // this.dispatch(sendVivaChargeToken(this.paymentDetails.amount * 100, this.paymentDetails.cardToken));
      // this.dispatch(requestVivaChargeTokenWithCard(this.paymentDetails.cardToken, this.paymentDetails.amount * 100));
    } catch (e) { }
  }

  sendConfirmation(info: any) {
    const axios = ClientAPI.getInstance();
    const pac = PlayerAbuseChecker.getInfo();
    const data = {
      chargeToken: info.data.chargeToken,
      amount: info.amount,
      bonusId: info.bonusId,
      ...pac,
    };

    axios({
      url: '/api/pay-checkout/charge-token',
      method: 'post',
      data: data,
    })
      .then((response) => {
        // @ts-ignore
        if (response?.result?.ResponseCode) {
          this.triggerError();
        } else {
          // @ts-ignore
          if (response?.status === 'OK') {
            setTimeout(this.triggerSuccess, 500);
          } else {
            this.triggerError();
          }
        }
      })
      .catch(() => {
        this.triggerError();
      });
  }

  getApplePay(): VivaApplePay | undefined {
    return this.applePay;
  }

  quickDeposit(data: any) {
    this.setPaymentDetails({
      amount: data.amount,
      bonusId: data.bonusId,
      card: data.card,
    });
    if (!data.loading) {
      this.auth();
    }

    // retry mechanism
    if (this.isAuthenticated()) {
      this.confirmPayment();
    } else {
      setTimeout(() => {
        this.quickDeposit({ ...data, loading: true });
      }, 300);
    }
  }

  isAuthenticated(): boolean {
    return VivaAPI.getToken() !== null;
  }
}

export default Viva;
