import { Container } from './Container/Container.jsx';
import { DataScroller } from './DataScroller/DataScroller/DataScroller';
import { DataScrollerItem } from './DataScroller/DataScrollerItem/DataScrollerItem';
import { DataScrollerItems } from './DataScroller/DataScrollerItems/DataScrollerItems';
import { DataGrid } from './DataGrid/DataGrid/DataGrid';
import { DataGridItem } from './DataGrid/DataGridItem/DataGridItem';
import { DataGridItems } from './DataGrid/DataGridItems/DataGridItems';
import { DataList } from './DataList/DataList/DataList';
import { DataListItem } from './DataList/DataListItem/DataListItem';
import { DataListItems } from './DataList/DataListItems/DataListItems';
import { DataElement } from './DataElement/DataElement';
import { Customizer } from './Customizer/Customizer';
import { MenuButton } from './MenuButton/MenuButton';
import { RootWrapper } from './RootWrapper/RootWrapper';
import { StateList } from './StateList/StateList/StateList';
import { StateListItem } from './StateList/StateListItem/StateListItem';
import { StateListItems } from './StateList/StateListItems/StateListItems';
import { StateScroller } from './StateScroller/StateScroller/StateScroller';
import { StateScrollerItem } from './StateScroller/StateScrollerItem/StateScrollerItem';
import { StateScrollerItems } from './StateScroller/StateScrollerItems/StateScrollerItems';
import { StateGrid } from './StateGrid/StateGrid/StateGrid';
import { StateGridItem } from './StateGrid/StateGridItem/StateGridItem';
import { StateGridItems } from './StateGrid/StateGridItems/StateGridItems';
import { StateElement } from './StateElement/StateElement';
import { Layout } from './Layout/Layout';
import { Carousel } from './Carousel/Carousel';
import { DataCarousel } from './DataCarousel/DataCarousel';
import { DataCarouselItem } from './DataCarousel/DataCarouselItem';
import { TabsLayout } from './TabsLayout/TabsLayout';
import { TabContainer } from './TabsLayout/TabContainer';
import { TabsContainer } from './TabsLayout/TabsContainer';
import { ModuleElement } from './ModuleElement/ModuleElement';
import { DialogButton } from './DialogButton/DialogButton';
import Input from './Input/Input';
import Textarea from './Textarea/Textarea';
import { WheelCanvas } from './WheelCanvas/WheelCanvas';
import Counter from './Counter/Counter';
import { Fragment } from './Fragment/Fragment';

import { ComponentData } from './ComponentData/ComponentData';
import { GoogleMap } from './GoogleMap';
import { TextsAnimation } from './TextsAnimation/TextsAnimation';

import { ModuleList } from './ModuleList/ModuleList/ModuleList';
import { ModuleListItem } from './ModuleList/ModuleListItem/ModuleListItem';
import { ModuleListItems } from './ModuleList/ModuleListItems/ModuleListItems';

import { Dropdown } from './Dropdown/Dropdown';
import { Collapse } from './Collapse/Collapse';

import { ElementData } from './ElementData/ElementData';

import { ChildrenSlot } from './ChildrenSlot/ChildrenSlot';

// special variant of the components with memo
import Image from './Image/Image';
import Link from './Link/Link.jsx';
import Text from './Text/Text';
import RichText from './RichText/RichText';
import LinkContainer from './LinkContainer/LinkContainer';
import BootstrapIcon from './BootstrapIcon/BootstrapIcon';
import Page from '../components/page/page';
import Odometer from './Odometer/Odometer';

import { ComponentRef } from './ComponentRef/ComponentRef';

import { HtmlObject } from './HtmlObject/HtmlObject';
import { DataSource } from './DataSource/DataSource';

import { Drawer } from './Drawer/Drawer';

import { LottiePlayer } from './LottiePlayer/LottiePlayer.jsx';

import dynamic from './dynamic.js';

interface DefaultComponents {
  [key: string]: (props: any) => JSX.Element | null;
}

const defaultComponents: any = {
  Text,
  RichText,
  Link,
  Image,
  Container,
  RootWrapper,
  DataScroller,
  DataScrollerItem,
  DataScrollerItems,
  DataGrid,
  DataGridItem,
  DataGridItems,
  DataList,
  DataListItem,
  DataListItems,
  Customizer,
  DataElement,
  MenuButton,
  LinkContainer,
  StateList,
  StateListItem,
  StateListItems,
  StateScroller,
  StateScrollerItem,
  StateScrollerItems,
  StateGrid,
  StateGridItem,
  StateGridItems,
  StateElement,
  Layout,
  Carousel,
  DataCarousel,
  DataCarouselItem,
  BootstrapIcon,
  TabsLayout,
  TabContainer,
  TabsContainer,
  PageContent: Page,
  ChildrenSlot,
  ModuleElement,
  Input,
  Textarea,
  DialogButton,
  WheelCanvas,
  Counter,
  ModuleList,
  ModuleListItem,
  ModuleListItems,
  Odometer,
  ComponentData,
  GoogleMap,
  TextsAnimation,
  Dropdown,
  Collapse,
  ElementData,
  ComponentRef,
  HtmlObject,
  DataSource,
  Drawer,
  LottiePlayer,
  Fragment,
  ...dynamic,
};

export default defaultComponents;
