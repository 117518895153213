import React from 'react';
import styled from 'styled-components';

import { DataElementContext } from '../../../page-components/common/DataElementContext';
import { useAppSelector } from '@/store';

//import './index.scss';

type PromoBubbleProps = {
  children: any;
  styleText: string;
  className: string;
  properties?: {
    dsType: string;
  };
};

const defaultProps = {
  className: '',
  styleText: '',
  properties: {
    dsType: '',
  },
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const PromoBubble = (componentProps: PromoBubbleProps) => {
  const tmpProps = { ...defaultProps, ...componentProps };
  delete tmpProps.children;
  const props = JSON.parse(JSON.stringify(tmpProps));
  const { children } = componentProps;

  const authentication = useAppSelector((state) => state.authentication);
  const [showPromoBubble, setShowPromoBubble] = React.useState<boolean>(false);

  let loggedIn = true;
  if (['user', 'token'].indexOf(authentication.auth_type) === -1 || authentication.access_token === null) {
    loggedIn = false;
  }

  const contextValue = React.useMemo(() => {
    return {
      showPromoBubble,
      loggedIn,
      togglePromoBubble: () => setShowPromoBubble(!showPromoBubble),
    };
  }, [showPromoBubble]);

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText}>
      <DataElementContext.Provider value={contextValue}>{children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default PromoBubble;
